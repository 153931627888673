/* Place your extensions here to make migration to a new release easier. */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900");

body {
  background-color: #edf0f5 !important;
  background-attachment: fixed;
}

$tabletBreakpoint: 756px;

.tags-scrollbar-thin {
  &::-webkit-scrollbar {
    height: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: gray;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }
}

#tags-dropdown {
  svg {
    opacity: 0;
  }
  transition: 0.2s all ease-in-out;
  &:hover {
    svg {
      opacity: 1;
    }
  }
}
#tags-dropdown-parent:hover + #tags-dropdown {
  svg {
    opacity: 1;
  }
}

.menu-social-icon-dark {
  color: #838383;
  i {
    color: #838383;
  }
  &:hover {
    color: #fff;
    i {
      color: #fff;
    }
  }
}

.cat-separator {
  // height:1px;
  display: flex;
  flex: 1;
  margin-right: 15px;
  border-bottom: 1px solid rgba(160, 160, 160, 0.25);
}

.menu-social-icon-light {
  color: #838383;
  i {
    color: #838383;
  }
  &:hover {
    color: #000;
    i {
      color: #000;
    }
  }
}

.tags-scrollbar {
  scrollbar-width: none !important;
  &::-webkit-scrollbar {
    width: 0px !important;
    color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    outline: none;
    overflow-x: hidden;
  }
}

.dropdown-menu {
  background-color: red;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  background-color: black;
}

.favIconClass {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
  padding-right: 10px;

  img {
    // position: relative;
    margin-top: 3px;
    max-width: 15px;
    max-height: 15px;
  }
}

.favIconClassSimillarPosts {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
  padding-right: 25px;

  img {
    // position: absolute;
    margin-top: 3px;
    max-width: 15px;
    max-height: 15px;
  }
}

.popupAddonsDark::before {
  display: flex;
  border-color: #3c3c3c !important;
  background-color: #3c3c3c !important;
  border-style: none;
  border-width: 10px;
  box-shadow: -1px -1px 0 0 #3c3c3c !important;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  // box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.popupAddonsLight::before {
  display: flex;
  background-color: #edf0f5 !important;
  border-color: #edf0f5 !important;
  border-style: none;
  border-width: 10px;
  box-shadow: -1px -1px 0 0 #edf0f5 !important;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  // box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.slick-custom-arrow {
  border-style: none;
  background: transparent;
  color: #808080;
  cursor: pointer;
  margin-top: -10px;
}

.float-right {
  margin-top: 3px;
  z-index: 100000000;
  // margin-top: 50px;
  width: 96%;
  height: 30vh;
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.blurred-box {
  position: relative;
  background: inherit;
  border-radius: 2px;
  overflow: hidden;
  width: 200px;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  &::-webkit-scrollbar {
    width: 3px;
    background-color: gray;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.blurred-box:before {
  content: "";
  position: absolute;
  background: inherit;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: inset 0 0 0 2000px rgba(60, 60, 60, 0.9);
  filter: blur(10px);
  -webkit-filter: blur(1px);
}

.blurred-box-light {
  position: relative;
  background: inherit;
  border-radius: 2px;
  overflow: hidden;
  width: 200px;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  &::-webkit-scrollbar {
    width: 3px;
    background-color: gray;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.blurred-box-light:before {
  content: "";
  position: absolute;
  background: inherit;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: inset 0 0 0 2000px rgba(237, 240, 245, 0.9);
  filter: blur(10px);
  -webkit-filter: blur(1px);
}

.CoverBanner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  // padding-top: 5px;
  margin-top: -2px;
  justify-content: center;
  background-size: 100%;
  min-height: 40vh;
  width: 100%;
  // padding-left: 5px;
  // padding-right: 5px;

  span {
    font-size: 25px;
    font-weight: bold;
    color: white;
    z-index: 1;
  }
}

.CoverBannerMobile {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  // padding-top: 5px;
  justify-content: flex-start;
  background-size: 100%;
  min-height: 180px;
  max-height: 180px;
  width: 100%;
  // padding-left: 5px;
  // padding-right: 5px;

  span {
    font-size: 25px;
    font-weight: bold;
    color: white;
    z-index: 1;
  }
}

.HomeBanner {
}

.ListsBanner {
}

.MobileTagsBar {
  padding-left: 0px;
}

.MobileTagsBarWithPadding {
  padding-left: 7px;
}

.MobileFilterBar {
  padding: 0px !important;
  width: 100%;
}

.MobileTagsBarForACpage {
  padding-left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DesktopTagsBar {
  padding-left: 240px;
}

.TagsBarOnIconMode {
  padding-left: 90px;
}

.DesktopFilterBar {
  padding-left: 250px !important;
}

.FilterBarOnIconMode {
  padding-left: 100px !important;
}

@media (max-width: $tabletBreakpoint) {
  .CoverBanner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-size: 100%;
    height: 23vh;
    width: 98%;

    span {
      font-size: 20px;
      font-weight: bold;
      color: white;
      z-index: 1;
    }
  }
}

.d-flex-end {
  display: flex !important;
  justify-content: flex-end !important;
}

.text-centered {
  text-align: center;
}

.custom-navlink {
  color: #000000de;

  &:hover {
    color: #000000a8;
  }

  .bold {
    color: #000000de;

    .wrapper {
      display: flex;
      justify-content: center;
    }
    .light {
      color: black;
    }
    div.cta {
      color: rgba(128, 128, 128, 1);
      font-weight: bold;
    }
    div.cta.light {
      color: rgba(128, 128, 128, 1);
    }
    .cta {
      display: flex;
      padding: 10px 13px;
      text-decoration: none;
      transition: 1s;
    }

    .cta:focus {
      outline: none;
    }

    .cta:hover {
      transition: 0.5s;
      color: rgba(158, 158, 158, 1);
    }
    .cta.light:hover {
      transition: 0.5s;
      color: rgba(98, 98, 98, 1);
    }

    .change-opacity-svg {
      opacity: 0.25;
      transform: scale(0.7);
    }

    .cta:hover > .change-opacity-svg {
      opacity: 1;
      transform: scale(0.8);
    }

    .cta span:nth-child(2) {
      transition: 0.5s;
      margin-right: 0px;
    }

    .cta:hover span:nth-child(2) {
      transition: 0.5s;
      // margin-right: 45px;
    }

    span:nth-child(2) {
      // width: 20px;
      margin-left: 2px;
      // margin-top: -1px;
      position: relative;
    }

    /**************SVG****************/

    path.one {
      transition: 0.4s;
      transform: translateX(-60%);
    }

    path.two {
      transition: 0.5s;
      transform: translateX(-30%);
    }

    .cta:hover path.one {
      transform: translateX(0%);
      animation: color_anim 1s infinite 0.6s;
    }

    .cta:hover path.two {
      transform: translateX(0%);
      animation: color_anim 1s infinite 0.4s;
    }

    /* SVG animations */

    @keyframes color_anim {
      0% {
        fill: rgba(255, 255, 255, 0);
      }
      50% {
        fill: rgba(128, 128, 128, 1);
      }
      100% {
        fill: rgba(255, 255, 255, 1);
      }
    }
  }

  cursor: pointer;
}

.main-app-container {
  display: flex;

  .hue-bar {
    width: 7px;
    display: inline;
    background: linear-gradient(
      #0040ff,
      #8000ff,
      #ff00bf,
      #ff0000,
      #ff8000,
      #40ff00,
      #00ffbf
    );
    // z-index: 1000;
  }
}

.layout-menu-container {
  padding-bottom: 0;
}

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #34384199;
}

.card {
  margin: 0px;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
}

.layout-logo {
  margin-top: 18px !important;
  padding-left: 0;

  h3 {
    margin-bottom: 18px !important;
    margin-left: 4px;
  }
}

html {
  &::-webkit-scrollbar {
    width: 0.6em;
  }

  &::-webkit-scrollbar-track {
    width: 0.6em;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    width: 0.6em;
    background-color: #9e9e9e;
    outline: 1px solid #9e9e9e;
  }
}

.centered {
  text-align: center;
}

.layout-sidebar {
  &::-webkit-scrollbar {
    width: 0.3em;
  }
}

.p-link {
  // background-color: red !important;
}

.layout-menu {
  padding-bottom: 110px;

  a.myAnchorInMenu {
    padding-bottom: 0.45em !important;
  }

  a {
    font-weight: bold;

    layout-topbar &:hover {
      // background-color: #2e3035;
    }
  }
}

.layout-topbar {
  width: 100vw;
  height: 55px;
  padding: 0;
  // z-index: 1000;
  display: flex;
  justify-content: space-between;
  // border:2px solid red;

  .layout-menu-button {
    // margin-top: 15px;
    // margin-left: 15px;
    padding-right: 10px;

    &:focus {
      box-shadow: none;
    }
  }

  // .layout-topbar-icons {
  //     width: calc(100% - 50px);
  //     // display: flex;
  //     // justify-content: space-around;
  //     // align-items: center;
  //     // border:1px solid white;
  //     float:left;

  .icon-center-content {
    width: 90%;
    max-width: 400px;
    display: flex;
    // border:1px solid red;

    /*For TABS ONLY -- 75VW  */
    @media only screen and (max-width: 800px) {
      // min-width: 220px;
      // margin-left: 30px;
    }

    input {
      width: 100% !important;
    }

    .pi-search {
      cursor: pointer;
    }
  }

  .mode-checkbox {
    margin-top: 6px;
    // margin-right: 20px;
  }
}

// }

.search-dialog {
  width: calc(0.5 * calc(100% - 50px));
  background-color: #edf0f5;
  position: absolute;
  margin-left: calc(25% + 35px);
  padding: 10px;
  box-shadow: 0 1px 20px 0 #0b253159;
  border: 1px solid #7a8e9759;

  /*For TABS ONLY -- 75VW  */
  @media only screen and (max-width: 800px) {
    min-width: 250px;
    margin-left: 80px;
  }
}

// .content-details:visited {
// 	color: gray;
// }
.content-details {
  height: 30px;
  max-height: 50px;
  margin-bottom: 10px;
  overflow: hidden;
  cursor: pointer;
  max-height: 60px;

  p {
    text-align: justify;
  }

  img {
    width: 100%;
  }
}

.loading-more-wrapper,
.loader-wrapper {
  img {
    height: 60px;
    margin: auto;
    margin-left: calc(50% - 35px);
  }

  width: 100%;
  margin-left: 0;
}

.underlined-border {
  border-bottom: 1px solid rgba(160, 160, 160, 0.25);
  padding-bottom: 5px;
  margin-bottom: 5px;
  padding-left: 35px !important;
  cursor: default;
}

.add-post-page {
  margin: auto;
}

.post-engagement-upvote {
  padding-left: 0;
  padding-right: 0;

  span {
    overflow: hidden;
  }
}

.highlighted {
  background-color: transparent;
  // box-shadow: inset 5px 5px 10px #e3e4e7, inset -5px -5px 10px #edf0f5;
}

.post-engagement-icon {
  height: 36.22px;
  padding-bottom: 0;
}

.post-engagement-icon,
.post-engagement-upvote,
.fav-similar {
  span {
    cursor: pointer;
  }

  i:before {
    color: #9e9e9e;
  }

  .hideShareOption {
    i:before {
      color: white;
    }
  }
}

//icon font size for topbar
.layout-main-icon-size {
  font-size: 35px;
  border: 1px solid red;
}

.glyph-icon {
  &::before {
    margin: 0;
    margin-right: 5px;
    margin-left: 5px;
    font-size: 18px;
  }

  &::after {
    margin: 0;
  }
}

.flaticon-plus.active,
.flaticon-plus:hover {
  &::before {
    content: "\f118";
  }
}

.flaticon-star.active,
.flaticon-star:hover {
  &::before {
    content: "\f11a";
  }
}

.flaticon-list.active,
.flaticon-list:hover {
  &::before {
    content: "\f120";
  }
}

.flaticon-settings.active,
.flaticon-settings:hover {
  &::before {
    content: "\f101";
  }
}

.flaticon-up.active,
.flaticon-up:hover {
  &::before {
    content: "\f12a";
  }
}

.flaticon-square.active,
.flaticon-square:hover {
  &::before {
    content: "\f12b";
  }
}

.flaticon-tag.active,
.flaticon-tag:hover {
  &::before {
    content: "\f110";
  }
}

.flaticon-share.active,
.flaticon-share:hover {
  &::before {
    content: "\f11c";
  }
}

.flaticon-up-3,
.flaticon-up-2 {
  &::before {
    font-size: 24px;
  }
}

// layout-sidebar-dark

.flaticon-up-2.active,
.flaticon-up-2:hover {
  &::before {
    content: "\f12d";
  }
}

.post-engagement-icon {
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media only screen and (max-width: 340px) {
    justify-content: flex-end;
  }
}

//for toggeling share option in card
.share-block {
  position: relative;
}

//of post card
.hideShareOption {
  display: flex;
  flex-direction: column;
  align-items: left;
  color: white;
  z-index: 1000;
  // background-color: #3c3c3c !important;
  padding: 5px;

  .icon-con-social {
    padding: 8px 15px;
    text-align: left !important;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  i {
    &::before {
      margin: 0;
      margin: 2px;
    }
  }
}

.Toastify {
  z-index: 100000000000;
}
//of list page
.list-hideShareOption {
  align-items: left;
  color: white;
  z-index: 1000;
  // background-color: #3c3c3c;
  padding: 5px;
  display: flex;
  flex-direction: column;
  width: 300px;

  > span {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    > div {
      display: flex;
      flex-direction: column;
    }
  }

  .icon-con-social {
    padding: 8px 15px;
    text-align: left !important;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  i {
    &::before {
      margin: 0;
      margin: 2px;
    }
  }
}

a:link {
  color: #9e9e9e;
}

a:visited {
  color: #9e9e9e;
  font-weight: 400;
}

.header-filter {
  display: flex;
  justify-content: space-between;
}

.icon-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.icon-center {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-center-content > input {
  background: transparent;
  border: 0px;
  border-bottom: 2px solid rgba(165, 165, 165, 0.5);
  border-radius: 0;
  color: grey !important;

  &:focus {
    box-shadow: none;
  }
}

.icon-center-content > input:hover {
  border-bottom: 2px solid rgba(165, 165, 165, 1) !important;
}

.icon-center-content > input::placeholder {
  color: grey;
}

.p-inputtext:focus {
  &:focus {
    box-shadow: none !important;
    border-bottom: 2px solid rgba(165, 165, 165, 1) !important;
  }
}

button.for-tooltip {
  background-color: transparent !important;
  outline: none;
  border: 0;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent !important;
    outline: none;
    border: 0;
    box-shadow: none !important;
  }

  .p-button-text {
    display: none !important;
    color: transparent !important;
  }
}

.flaticon-icon {
  &::before {
    margin-left: 0 !important;
    margin-right: 8px;
  }
}

.ui.modal.clear-favourite-modal {
  // width: 90%;
  // max-width: 400px;

  // > div {
  // 	background: transparent !important;
  // }
  color: gray;
  width: 90%;
  border-radius: 2px;
  padding: 30px;
  background-color: #edf0f5;
  max-width: 450px;

  > div {
    color: gray;
    background: #edf0f5;
  }
}

.layout-menu-fixed-top {
  position: fixed;
  top: 55px;
  z-index: 100;
  width: calc(243.5px - 0.3em);
  background-color: #edf0f5;
  border-top: 0px solid rgba(192, 192, 192, 0.2);
  padding: 0;

  a {
    padding-top: 10px !important;
  }
}

.layout-menu-fixed {
  position: fixed;
  bottom: 0;
  width: calc(243.5px - 0.3em);
  // padding-bottom: 0px;
  background-color: #dcdfeb;
  border-top: 1px solid rgba(192, 192, 192, 0.2);
  padding: 0;

  a {
    padding-top: 10px !important;
  }
}

.firefox {
  width: calc(240.5px - 0.3em);
}

.p-dropdown {
  margin-right: 5px;
}

.tags-wrapper {
  width: 200px;
}

.similars-popup {
  width: 100% !important;
  height: 265px;
  overflow-y: scroll;
}

.similar-post-title {
  max-height: 40px;
  overflow: hidden;
}

.similar-post-content {
  color: gray;
  height: 20px;
  overflow: hidden;
  font-size: 0.9em;
}

//add-post
.addpost-category {
  > .ui.fluid.dropdown {
    padding-left: 12px !important;
  }

  &::selection {
    i {
      color: black;
    }
  }
  i {
    margin-right: 12px;
    color: #666;
  }
}

//add-post
.addpost-category-dark {
  > .ui.fluid.dropdown {
    background-color: #cccccc;
    padding-left: 12px !important;
  }

  &::selection {
    i {
      color: black;
    }
  }
  i {
    margin-right: 12px;
    color: #666;
  }
}

//add-post
.addpost-category-modal {
  > .ui.fluid.dropdown {
    padding-left: 12px !important;
  }

  .item {
    span {
      color: black;
    }
  }

  &::selection {
    i {
      color: black;
    }
  }
  i {
    margin-right: 12px;
    color: #666;
  }
}

//add-post
.addpost-category-dark-modal {
  > .ui.fluid.dropdown {
    background-color: #cccccc;
    padding-left: 12px !important;
  }

  .item {
    span {
      color: white;
    }
  }

  &::selection {
    i {
      color: black;
    }
  }
  i {
    margin-right: 12px;
    color: #666;
  }
}

.dropdown[aria-expanded="true"] {
  .add-category-icon {
    color: rgba(0, 0, 0, 0.8);
  }
}

//tags input css things
.tags-input {
  display: inline-flex;
  align-items: center;
  // margin: 0 auto;
  padding: 5px;
  width: 100%;
  font-size: 14px;
  border: #cecece solid 1px;
  overflow-x: visible;
  overflow-y: visible;
  border-radius: 3px;
  min-height: 37.88px;
  padding-left: 8px;
  background-color: white;

  .tags-input-icon {
    color: #888;
    background-color: transparent !important;
    margin-right: 12px !important;
    padding-top: 0px !important;
  }

  .tag-label {
    white-space: nowrap;
  }

  .tag {
    margin-right: 5px;
  }

  input {
    width: 100%;
    border: none;
    outline: none;

    &::placeholder {
      color: silver;
      padding: 0 !important;
    }
  }

  .tag {
    display: inline-flex;
    padding: 2px 5px;
    background-color: #cecece;
    border-radius: 3px;
  }

  button.delete {
    display: inline-block;
    margin-left: 5px;
    padding: 0;
    width: 15px;
    height: 15px;
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 50px;
    border: none;
    outline: none;
    user-select: none;
  }

  button.delete:after {
    content: "X";
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
  }

  .help {
    font-size: 12px;
    color: #555;
  }
}

.list-page-share-block {
  .icon-con-social {
    height: 70px !important;
    padding-top: 9px;
    border: 0;
    border: 1px solid silver;

    i {
      &::before {
        margin: 0;
        color: white;
        cursor: pointer;
      }

      padding: 4px;
    }
  }
}

.list-item {
  > div {
    height: 100%;

    > div {
      height: 100%;
    }
  }
}

.list-item-title {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.topbar-nepathya {
  height: 63px;
  background-color: #017ad9;
}

.menuitem-custom-tooltip.visible {
  visibility: hidden;
  position: fixed;
  z-index: 99999;
  background-color: #edf0f5;
  box-shadow: -2px 1px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  left: 80px;
  margin-top: 5px;
  color: white;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: -13px;
    border-width: 7px;
    margin-top: -7px;
    border-style: solid;
    border-color: transparent #edf0f5 transparent transparent;
  }
}

.dark-body .menuitem-custom-tooltip.visible {
  visibility: hidden;
  position: fixed;
  z-index: 99999;
  background-color: #3c3c3c;
  box-shadow: -2px 1px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  left: 80px;
  margin-top: 5px;
  color: white;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: -13px;
    border-width: 7px;
    margin-top: -7px;
    border-style: solid;
    border-color: transparent #3c3c3c transparent transparent;
  }
}

.menuitem-custom-tooltip.invisible {
  visibility: hidden;
  height: 0;
}

/*For TABS ONLY -- 75VW  */
@media only screen and (min-width: 450px) and (max-width: 800px) {
}

//for responsivity
.mobile-font {
  @media only screen and (max-width: 450px) {
    font-size: 0.9em !important;
  }
}

.chevron.right.small.icon {
  margin-left: 14px !important;
  margin-top: 1px !important;
}

.menuitem-category-884886:hover {
  a,
  .label-id-884886 {
    color: gray !important;
  }
}

.menuitem-category-884886.active-menuitem {
  a,
  .label-id-884886 {
    color: gray !important;
  }
}

.ui.pointing.dropdown > .menu:after {
  background: transparent !important;
}

// .ui.menu .item {
//   color: white !important;
// }

.thumbnail-card-list-grid {
  position: absolute;
  left: 0;
  top: -10px;
  transition: width 0.2s; /* Animation */
  &:hover {
    width: 160% !important;
  }
}

.item {
  color: black !important;
}

.dark-body {
  .dropdown {
    .menu {
      .itemWhite {
        color: white !important;
      }
    }
  }
}

.shareIcon {
  display: none;
}

.list-item:hover {
  .shareIcon {
    display: inline;
  }
}

.iconLibrary {
  background-color: #edf0f5;
  // box-shadow: 20px 20px 20px 0px rgba(0, 0, 0, 0.15);
  margin: 5px;
  width: 100%;
  margin-top: 15px;
  margin-right: 15px;
  height: 100%;

  // .spaceAround {
  //   justify-content: space-between;
  // }
  &_title {
    font-size: 16px;
    font-weight: bolder;
    color: #333333;
    padding: 10px;
    border-bottom: 1px solid #969191;
  }
  &_list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: center;
  }
  &_item {
    height: 40px;
    width: 40px;
    margin: 30px;
    padding: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &_img {
    height: 100%;
    width: 100%;
  }
  &_name {
    margin: 3px;
    font-size: 14px;
    font-weight: bolder;
    color: gray;
  }
  &_load-img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
}

.mobile-item {
  height: 50px;
  width: 50px;
  margin: 25px;
}

.mobile-name {
  font-size: 13px;
}

.dark {
  color: white;
  border-bottom: 1px solid white;
}

.dark-background {
  background-color: #3c3c3c;
  box-shadow: 20px 20px 20px 0px rgba(0, 0, 0, 0.25);
}

.loading-icon-mobile {
  height: 130px;
}

.noImageText {
  color: gray;
  font-size: 13px;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  height: 179px;
  width: 318px;
}

.noImageText__mobile {
  color: gray;
  font-size: 13px;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  padding: 30px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
}

.goHomeRow {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  font-weight: bolder;
  .goHome {
    background-color: white;
    border-radius: 17px;
    padding: 10px;
  }
}

.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
}

.desktop-recaptcha {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 640px) {
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    width: 240px;
  }
}

.category-label {
  margin-top: -4px !important;
  padding-left: 3px;
}

.label-id-884886 {
  margin-top: -2px !important;
  margin-left: 0px !important;
}
.layout-static-sidebar-inactive .menuitem-category-884886 img {
  margin-top: -4px !important;
  margin-left: 2px !important;
}
.menuitem-category-884886 img {
  margin-top: 3px !important;
  margin-left: -0px !important;
}

.menuitem-category-884886 .arrow-left-white,
.menuitem-category-884886 .arrow-left {
  margin-top: 3px !important;
}

.layout-static-sidebar-inactive .menuitem-category-884886 .arrow-left-white,
.layout-static-sidebar-inactive .menuitem-category-884886 .arrow-left {
  margin-top: -2px !important;
  margin-left: 2px !important;
}

.layout-static-sidebar-inactive .arrow-left-white,
.layout-static-sidebar-inactive .arrow-left {
  margin-top: 4px !important;
  margin-left: 2px !important;
}

li.menuitem-category-884886,
li.active-menuitem a,
.dark-body li.menuitem-category-884886,
.dark-body li.active-menuitem a {
  max-height: 51px !important;
}

.dark-body .menuitem-custom-tooltip.visible:first-child {
  margin-top: 22px !important;
}
/*
.layout-menu li:last-child {
  display: none;
}
*/
.layout-menu li.sub-btn {
  display: block;
}
.layout-menu li.sub-btn i {
  color: grey;
  margin-left: -2px;
}

.layout-menu li.sub-btn span.mobile-font {
  margin-left: 10px;
}
.layout-static-sidebar-inactive .layout-menu li.sub-btn i {
  transform: scale(1.2);
  margin-left: 8px;
}

.layout-static-sidebar-inactive a.myAnchorInMenu {
  padding-bottom: 12px !important;
}
.layout-static-sidebar-inactive a.myAnchorInMenu img {
  margin-top: -6px !important;
  margin-left: 2px !important;
}

.tags-popup {
  border: none !important;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3) !important;
}

.simillar-popup {
  border: none !important;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3) !important;
  border-style: solid;
  background-color: #edf0f5 !important;
}
.simillar-popup .circular .label {
  box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.2);
  color: grey;
}
.simillar-popup .circular .label {
  background-color: rgba(255, 255, 255, 0.35);
}
.simillar-popup .circular .label:hover {
  color: black;
  background-color: rgba(255, 255, 255, 0);
}
.dark-body .simillar-popup .circular .label {
  background-color: rgba(128, 128, 128, 0.1);
}

hr {
  opacity: 0.3;
}
.dark-body hr {
  opacity: 0.15;
}

.active-menuitem:active {
  outline: none !important;
  box-shadow: 2px 8px 10px 0px rgba(0, 0, 0, 0.15);
}

.dark-body .active-menuitem:active {
  outline: none !important;
  box-shadow: 2px 8px 10px 0px rgba(0, 0, 0, 0.25);
  border-top: 0px solid rgba(128, 128, 128, 0.05);
}

ul.layout-menu li:active {
  outline: none !important;
  box-shadow: 2px 8px 10px 0px rgba(0, 0, 0, 0.15);
  background-color: #edf0f5;
}

.dark-body ul.layout-menu li:active {
  outline: none !important;
  box-shadow: 2px 8px 10px 0px rgba(0, 0, 0, 0.25);
  background-color: #3c3c3c;
}

.menuitem-category-884886:active {
  box-shadow: 2px 8px 10px 0px rgba(0, 0, 0, 0) !important;
  background-color: #edf0f5;
}

.dark-body .menuitem-category-884886:active {
  box-shadow: 2px 8px 10px 0px rgba(0, 0, 0, 0) !important;
  background-color: #3c3c3c;
}

.post-card-image-listview-mobile .noImageText__mobile {
  display: none;
}

@media only screen and (max-width: 800px) {
  .post-card {
    box-shadow: 2px 8px 15px 0px rgba(0, 0, 0, 0.25) !important;
  }
}

.layout-mobile-sidebar-active .layout-sidebar {
  box-shadow: 5px 8px 15px 0px rgba(0, 0, 0, 0.2) !important;
}

.date-text,
.pi-clock {
  visibility: hidden;
}
.recents-page .date-text,
.recents-page .pi-clock {
  visibility: visible !important;
}

.label.circular {
  background-color: rgba(255, 255, 255, 0.35);
}

.label.circular:hover {
  color: rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0);
}

.dark-body .label {
  background-color: rgba(128, 128, 128, 0.1);
}

.dark-body .label:hover {
  color: rgb(255, 255, 255) !important;
  background-color: rgba(128, 128, 128, 0);
}

.active .label.grey {
  border-color: rgba(135, 135, 135, 1) !important;
  background-color: rgba(135, 135, 135, 1) !important;
}
.dark-body .active .label.grey {
  border-color: rgba(110, 110, 110, 1) !important;
  background-color: rgba(110, 110, 110, 1) !important;
}

.post-engagement-icon {
  margin-left: -8px;
  margin-top: 2px;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
}

.post-engagement-favourite {
  border: 0px solid rgb(0, 60, 255);
  margin-left: 25px;
}

.ui.dropdown .menu {
  box-shadow: -4px 6px 8px 0px rgba(0, 0, 0, 0.15) !important;
  border-top: none;
}

.scroll-menu-arrow {
  visibility: hidden;
}
.horizontal-menu:hover > .scroll-menu-arrow {
  visibility: visible;
  border: 0px solid red;
}

/*

.layout-sidebar {
  height: 100% !important;
  border: 0px solid red !important;
  background-color: #d7dbe8 !important;
  margin-left: 7px;
}

.layout-menu-container {
  margin-left: -7px;
}


/*
.menu-wrapper:hover + .scroll-menu-arrow i.flaticon-right-arrow-1 {
  margin-left: 40px;
  display: block !important;
}
.menu-wrapper:hover + .scroll-menu-arrow i.flaticon-left-arrow-1 {
  margin-left: -40px;
  display: block !important;
}

.scroll-menu-arrow i.flaticon-right-arrow-1 {
  margin-left: -100px !important;
}
.scroll-menu-arrow i.flaticon-left-arrow-1 {
  margin-left: 80px !important;
}
*/

.tags-popup .circular .label {
  box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.2);
  color: grey;
}
.tags-popup .circular .label {
  background-color: rgba(255, 255, 255, 0.35);
}
.tags-popup .circular .label:hover {
  color: black;
  background-color: rgba(255, 255, 255, 0);
}
.dark-body .tags-popup .circular .label {
  background-color: rgba(128, 128, 128, 0.1);
}

.tags-popup {
  background-color: #edf0f5 !important;
}

.single-post-page .post-card {
  box-shadow: 10px 10px 12px 0 rgba(0, 0, 0, 0.15);
}

.random-post-button {
  position: fixed;
  z-index: 1001;
  bottom: 10px;
  right: 10px;
  padding: 10px;
  font-size: 1.2em;
  background-color: grey;
  border-radius: 48px;
  cursor: pointer;
  box-shadow: 0 1px 5px 0 #4b4d4e9c;
  text-align: right;
  display: inline-block;
  vertical-align: bottom;
  height: 40px;
  border: 0px solid grey;

  &:hover {
    padding: 10px;
    border: 0px solid blue;
    box-shadow: 0 1px 5px 0 #4b4d4e9c;
  }

  i {
    color: white;
    margin: 0;

    &::before {
      margin: 0;
    }
  }

  &::before {
    content: "RANDOM LINK";
    color: transparent;
    font-weight: bold;
    font-size: 80%;
    display: inline-block;
    white-space: nowrap;
    max-width: 0;
    transition: max-width 0.6s;
    vertical-align: bottom;
    overflow: hidden;
    padding-top: 1px;
  }

  &:hover::before {
    color: white;
    max-width: 120px;
    width: 120px;
    text-align: left;
    transition: max-width 0.5s, color 0.3s 0.3s ease-in-out;
    padding-left: 10px;
  }

  @media only screen and (max-width: 450px) {
    bottom: 15px;
    right: 15px;
  }
}

.post-modal-dark {
  display: flex;
  align-items: flex-start;
  max-width: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 90%;
  border-radius: 0px;
  scrollbar-width: thin;
  background-color: #3c3c3c;
  scrollbar-color: lightgray rgb(41, 40, 40);
  padding: 0px;
  min-height: 700px;
}

.post-modal-light {
  display: flex;
  align-items: flex-start;
  max-width: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 90%;
  border-radius: 0px;
  scrollbar-width: thin;
  background-color: lightgray;
  scrollbar-color: rgb(41, 40, 40) lightgray;
  padding: 0px;
}

.main-swiper-modal {
  height: 93%;
  width: 100%;
  right: 0px;
}

.post-modal-slider-dark {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex: 1;

  .left-swipe {
    left: 0px;
    background-color: transparent;
    padding: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 0.3;
  }
  .swiper-body {
    overflow-x: hidden;
    max-height: 100%;
    min-height: 100%;
    border-radius: 0px;
    scrollbar-width: thin;
    background-color: #3c3c3c;
    scrollbar-color: lightgray rgb(41, 40, 40);
    display: flex;
    padding: 0px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 0.4;
  }
  .right-swipe {
    right: 0px;
    background-color: transparent;
    padding: 10px;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    flex: 0.3;
  }
}

.post-modal-slider-light {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex: 1;

  .left-swipe {
    left: 0px;
    background-color: transparent;
    padding: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 0.3;
  }
  .swiper-body {
    overflow-x: hidden;
    max-height: 100%;
    min-height: 100%;
    border-radius: 0px;
    scrollbar-width: thin;
    background-color: lightgray;
    scrollbar-color: rgb(41, 40, 40) lightgray;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    flex: 0.4;
  }
  .right-swipe {
    right: 0px;
    background-color: transparent;
    padding: 10px;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    flex: 0.3;
  }
}

.post-link {
  display: flex;
  .open-new-post-page {
    display: none;
    margin-left: 0px;
    margin-top: 2px !important;
    cursor: pointer;
  }

  .open-new-post-page-dark {
    display: none;
    margin-left: 0px;
    margin-top: 2px !important;
    cursor: pointer;
  }
}

.post-link:hover {
  display: flex;
  .open-new-post-page {
    display: block;
    margin-left: 10px;
    margin-bottom: -2px;
    cursor: pointer;
    svg {
      color: #737373;
    }
    &:hover {
      svg {
        color: black;
      }
    }
  }

  .open-new-post-page-dark {
    display: block;
    margin-left: 10px;
    margin-bottom: -2px;
    cursor: pointer;
    svg {
      color: lightgray;
    }
    &:hover {
      svg {
        color: white;
      }
    }
  }
}

.tags-dark-mode {
  background-color: #3c3c3c;
}

.tags-light-mode {
  background-color: #edf0f5;
}

.remove-padding {
  padding: 0px;
}

.post-modal-head-dark {
  width: 100%;
  max-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;

  div {
    flex: 0.5;
    display: flex;

    span {
      font-weight: bold;
      padding: 15px;
      color: white;
      font-size: 13px;
    }
  }
}

.post-modal-head-light {
  width: 100%;
  max-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #edf0f5 !important;

  div {
    flex: 0.5;
    display: flex;

    span {
      font-weight: bold;
      padding: 15px;
      color: black;
      font-size: 13px;
    }
  }
}

.post-modal-body-dark {
  min-height: 700px;
  margin-top: 50px;
  background-color: #3c3c3c !important;
}

.post-modal-body-light {
  min-height: 700px;
  margin-top: 50px;
  // border-style: solid;
  background-color: #edf0f5 !important;
}

// filter bar style
.filter-bar {
  position: relative;
  min-height: 110px;
  max-height: 110px;
  box-shadow: inset 0px 0px 0px 0 rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #edf0f5;
  width: 100%;

  .menu-item {
    padding: 0 40px;
    user-select: none;
    cursor: pointer;
    border: none;
  }

  .menu-item-wrapper {
    border: none !important;
    padding: 10px;
  }

  .ui.label {
    box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.2);
    color: grey;
  }

  .menu-item-wrapper.active,
  .menu-item-wrapper {
    outline: none !important;
  }

  .menu-item.active {
    border: none !important;
  }

  .scroll-menu-arrow {
    padding: 12px;
    cursor: pointer;
    color: #b0b1b4;
    transform: scale(0.5) !important;
  }

  .scroll-menu-arrow--disabled {
    visibility: hidden;
  }

  .label.circular {
    cursor: pointer;
    border-width: 3px;
    font-size: 0.925em;
    outline: none !important;

    .tag-span {
      outline: none !important;
    }

    .tag-span.active {
      text-decoration: underline;
      color: black;
    }
  }
}

.FilterIconCont {
  background-color: #f2f5f8;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  img {
    transition: all 0.5s ease-in-out;
  }

  &:hover {
    img {
      transform: scale(1.2);
    }
  }
}

.FilterIconContDark {
  background-color: #3c3c3c;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  img {
    transition: all 0.5s ease-in-out;
  }

  &:hover {
    img {
      transform: scale(1.2);
    }
  }
}

.filter-bar-comp {
  padding: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // padding: 2px;
  cursor: pointer;

  span {
    font-weight: bold;
    color: #8a8a8a;
  }
}

.filter-bar.fixed {
  position: fixed;
  box-shadow: inset 0px 0px 0px 0 rgba(0, 0, 0, 0.1);
  z-index: 2;
  height: 85px !important;
  width: 100%;
}

.page-about {
  margin-top: 0px;
}

.page-terms {
  margin-top: 0px;
}

.page-privacy {
  margin-top: 0px;
}

.page-platforms {
  margin-top: 0px;
}

.post-title-light {
  color: black;
}

.post-title-dark {
  color: white;
}

.top-bar-active {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  padding: 3px;
}

.subscribeText:hover {
  color: blue;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.ask-modal-dark {
  display: flex;
  align-items: flex-start;
  max-width: 400px;
  overflow-y: scroll;
  max-height: 90%;
  border-radius: 0px;
  scrollbar-width: thin;
  background-color: #3c3c3c !important;
  scrollbar-color: lightgray rgb(41, 40, 40);
  padding: 0px;
  // min-height: 700px;
}

.ask-modal-light {
  display: flex;
  align-items: flex-start;
  max-width: 400px;
  overflow-y: scroll;
  max-height: 90%;
  border-radius: 0px;
  scrollbar-width: thin;
  background-color: #edf0f5 !important;
  scrollbar-color: #3c3c3c;
  padding: 0px;
  // min-height: 700px;
}

.ask-modal-head-dark {
  width: 100%;
  border-radius: 0px;
  background-color: #3c3c3c !important;

  div {
    flex: 0.5;
    display: flex;

    span {
      color: white;
      font-size: 14px;
    }
  }
}

.ask-modal-head-light {
  border-radius: 0px;
  width: 100%;
  background-color: #edf0f5 !important;

  div {
    flex: 0.5;
    display: flex;

    span {
      color: black;
      font-size: 14px;
    }
  }
}

.feedback-modal-dark {
  display: flex;
  align-items: flex-start;
  max-width: 400px;
  overflow-y: scroll;
  max-height: 90%;
  border-radius: 0px;
  scrollbar-width: thin;
  background-color: #3c3c3c !important;
  scrollbar-color: lightgray rgb(41, 40, 40);
  padding: 0px;
  // min-height: 700px;
}

.feedback-modal-light {
  display: flex;
  align-items: flex-start;
  max-width: 400px;
  overflow-y: scroll;
  max-height: 90%;
  border-radius: 0px;
  scrollbar-width: thin;
  background-color: #edf0f5 !important;
  scrollbar-color: #3c3c3c;
  padding: 0px;
  // min-height: 700px;
}

.pwa-uni-modal-dark {
  max-width: 400px;
  min-width: 399px;
  padding: 15px;
  background-color: #3c3c3c !important;
  color: white;

  p {
    line-height: 1.6;
  }
}

.pwa-uni-modal-light {
  max-width: 400px;
  min-width: 399px;
  padding: 15px;
  background-color: #edf0f5 !important;
  color: black;

  p {
    line-height: 1.6;
  }
}

.feedback-modal-head-dark {
  width: 100%;
  border-radius: 0px;
  background-color: #3c3c3c !important;

  div {
    flex: 0.5;
    display: flex;

    span {
      color: white;
      font-size: 14px;
    }
  }
}

.feedback-modal-head-light {
  border-radius: 0px;
  width: 100%;
  background-color: #edf0f5 !important;

  div {
    flex: 0.5;
    display: flex;

    span {
      color: black;
      font-size: 14px;
    }
  }
}

.testtt {
  margin-top: 50px;
}

.drag {
  top: auto !important;
  right: auto !important;
  left: auto !important;
  position: relative !important;
  margin: 0px !important;
  padding: 0px !important;
}

.tag-scroll {
  padding: 0px !important;
}

.tag-scroll::-webkit-scrollbar {
  height: 2px;
}

.btm-menu {
  a {
    span {
      color: black;
    }
  }
}

.btm-dark {
  a {
    span {
      color: white !important;
    }
  }
}

.btm-dark:hover {
  a {
    span {
      color: gray !important;
    }
  }
}

.btm-menu:hover {
  a {
    span {
      color: gray;
    }
  }
}

.postLinkTypes {
  width: 100%;
  padding-left: 10px;
  min-height: 50px;

  span {
    font-weight: bold;
    margin-left: 15px;
    color: gray;
    font-size: 12px;
  }
}

.addpost-category {
  .divider {
    img {
      display: none !important;
    }
  }

  .ui {
    .avatar {
      border-radius: 0px !important;
      border-style: none !important;
      outline: none !important;
      box-shadow: none !important;
      width: 25px !important;
      height: 25px !important;
    }

    .avatar[src=""],
    .avatar:not([src]) {
      opacity: 0 !important;
    }
  }
}

.addpost-category-modal {
  .divider {
    img {
      display: none !important;
    }
  }

  .ui {
    .avatar {
      border-radius: 0px !important;
      border-style: none !important;
      outline: none !important;
      box-shadow: none !important;
      width: 25px !important;
      height: 25px !important;
    }

    .avatar[src=""],
    .avatar:not([src]) {
      opacity: 0 !important;
    }
  }
}

.copy-share-input {
  input {
    width: 120px;
    border-style: solid;
    border-width: 2px;
    border-color: red;
  }
}

.plartform-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0px 10px;

  .enabled {
    &:hover {
      background-color: rgba(0, 0, 0, 0.25);
    }
  }

  .disabled {
    opacity: 0.4;
  }
  .plartform-item {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    width: 49.3%;
    cursor: pointer;
    @media only screen and (max-width: 600px) {
      width: 95%;
    }
    border-radius: 3px;
    border-color: rgba(128, 128, 128, 0.3);
    border-style: solid;
    border-width: 2px;
    background-color: rgba(0, 0, 0, 0);

    svg {
      margin-right: 5px;
      font-size: 30px;
      color: #808080;
    }

    span {
      font-size: 16px;
      font-weight: 700;
      color: #808080;
    }

    img {
      width: 180px;
      height: 50px;
    }

    .subimg {
      max-width: 30px !important;
      max-height: 30px !important;
    }
  }
}

.plartform-container-light {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0px 10px;

  .plartform-item {
    margin-top: 15px;
    display: flex;
    min-height: 150px;
    width: 49.3%;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 600px) {
      width: 95%;
    }
    border-radius: 3px;
    border-color: rgba(128, 128, 128, 0.3);
    border-style: solid;
    border-width: 2px;
    background-color: rgba(0, 0, 0, 0);
    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }

    svg {
      font-size: 30px;
      margin-right: 5px;
      color: #808080;
    }

    span {
      font-size: 16px;
      font-weight: bold;
      color: #808080;
    }
    img {
      width: 180px;
      height: 50px;
    }
  }
}

.icon-lib-text {
  &:hover {
    color: black;
  }
}

.icon-lib-text-dark {
  &:hover {
    color: white;
  }
}

.theme-btn {
  border-style: none;
  border-radius: 2px;
  padding: 10px;
  width: 100%;
  background-color: black;
  color: white;
  cursor: pointer;
  font-weight: bold;

  &:disabled {
    background-color: gray;
    color: lightgray;
    cursor: not-allowed;
  }
}

.ui.selection.dropdown .menu {
  border-color: gray !important;
}
.ui.selection.dropdown:focus .menu {
  border-color: none;
}
.ui.selection.active.dropdown,
.ui.selection.active.dropdown .menu {
  border-color: none;
}
.ui.selection.active.dropdown:hover,
.ui.selection.active.dropdown:hover .menu {
  border-color: none;
}
.ui.selection.dropdown .menu > .item {
  border-top: 1px solid grey;
}

.ui.dropdown .menu > .item > .image,
.ui.dropdown .menu > .item > img {
  margin-top: -0.2em;
  margin-bottom: -0.2em;
  max-height: 1.25em;
  max-width: 1.25em;
}

.datee {
  background-color: transparent !important;
}

.top-bar-link-light {
  &:hover {
    color: black;
  }
}

.top-bar-link-dark {
  &:hover {
    color: white;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.textarea {
  display: flex;
  flex-direction: column;
  span {
    opacity: gray;
  }

  .textarea-input {
    margin-top: 3px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 35px;
    background-color: #ffffff;
    padding-left: 10px;
    border-radius: 4px;
    padding-right: 10px;

    span {
      color: gray;
      font-weight: bold;
      font-size: 13px;
    }

    i {
      font-size: 15px;
      color: gray;
    }
  }

  .textarea-input-dark {
    margin-top: 3px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 35px;
    background-color: #cccccc;
    padding-left: 10px;
    border-radius: 4px;
    padding-right: 10px;

    span {
      color: gray;
      font-weight: bold;
      font-size: 13px;
    }

    i {
      font-size: 15px;
      color: gray;
    }
  }

  textarea {
    flex: 1;
    border-style: none;
    background-color: transparent !important;
    border-radius: 0px !important;
    min-height: 35px;
    line-height: 27px;
    border: none;
    overflow: auto;
    outline: none;
    resize: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    scrollbar-width: none;

    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #d1d1d1;
    }
    &::-webkit-input-placeholder {
      /* Webkit */
      color: #d1d1d1;
    }
    &::-ms-input-placeholder {
      /* IE */
      color: #d1d1d1;
    }

    padding: 5px;
  }
}

.add-post-modal {
  scrollbar-width: thin !important;
  scrollbar-width: 1px;
  &::-webkit-scrollbar {
    width: 4px; /* Safari and Chrome */
    background-color: gray;
  }
}

.url-input-dark {
  .ui.fluid.input > input {
    background-color: #cccccc;
  }
}

.url-input-light {
  .ui.fluid.input > input {
    background-color: white;
  }
}

.similar-post-content {
  height: 25px;
}
.favIconClassSimillarPosts {
  padding-right: 6px;
}

// .similar-post-content a.inpost-link,
// .similar-post-content a.inpost-link:visited {
// }

.similar-post-content {
  display: flex;
  align-items: baseline;
}

.unsubscribe {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20vh;
  flex-direction: column;
  span {
    font-weight: bold;
    font-size: 18px;
    padding: 20px 0px;
  }
}

.rectangle-tags {
  margin-left: 4px !important;
}

.rectangle-tags-dark {
  margin-left: 4px !important;
  color: gray !important;
  &:hover {
    color: white !important;
    background-color: #434343 !important;
  }
}

.icon-modal-content {
}

.info-dialog {
  position: sticky;
  width: 100%;
  bottom: 0px;
  padding: 15px 15px;
  text-align: left;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px 20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px 30px;

  &__container {
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: rgba(116, 116, 116, 0.1);
    }

    span {
      font-size: 13px;
    }
  }
}

// .how-to-media {

// 	video {

// 		width: 100% !important;
// 		height:100% !important;
// 		padding: 0px !important;
// 		margin: 0px !important;
// 	}

// 	// img {
// 	// 	width: 100% !important;
// 	// 	margin: 0px !important;
// 	// 	padding: 0px !important;
// 	// }

// 	figure {
// 		width: 100% !important;
// 		padding: 0px !important;
// 		margin: 0px !important;
// 		overflow:auto;
// 	}

// 	// iframe {
// 	// 	width: 100% !important;
// 	// }
// }

#fav-container {
  > #menu-span {
    margin-right: 12px !important;

    > div {
      box-shadow: none !important;
      border-style: none !important;
      z-index: 100;
      position: relative;
      height: 41px;
      padding-right: 0px !important;
      padding-top: 0px;
      padding-left: 0px;
      padding-bottom: 0px;
      background-color: transparent !important;

      > div {
        &:hover {
          background-color: transparent;
        }
        &:active {
          background-color: transparent;
        }

        &::before {
          background-color: transparent;
        }
      }
    }
  }

  > #menu-span-light {
    margin-right: 5px !important;

    > .ui {
      padding-left: 0px !important;
    }

    > div {
      box-shadow: none !important;
      border-style: none !important;
      z-index: 100;
      position: relative;
      height: 41px;
      padding-right: 0px !important;
      padding-top: 0px;
      padding-left: 0px;
      padding-bottom: 0px;
      background-color: white !important;

      &::before {
        background-color: transparent !important;
      }

      > div {
        &:hover {
          background-color: transparent !important;
        }
        &:active {
          background-color: transparent !important;
        }

        &::before {
          background-color: transparent !important;
        }
      }
    }
  }
}

.upvote-container {
  display: flex;
  flex-direction: column;
  width: 300px;

  .upvote-container-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex: 1;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    padding: 15px 5px;
    margin: 5px 0px;
    border-color: rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: #f2f5f8;
    }

    &:active {
      background-color: #f2f5f8;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0.15;
      color: #8a8a8a;

      svg {
        color: #8a8a8a;
        font-size: 27px !important;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex: 0.75;
      padding: 0px 5px;

      .text-top {
        font-size: 10px;
        font-weight: bold;
        font-style: italic;
      }

      .text-bottom {
        font-size: 12px;
        font-weight: normal;
      }
    }

    &__checkbox {
      flex: 0.1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .upvote-input-container {
        width: 15px;
        height: 15px;
        background-color: transparent;
        border: 2px solid #8a8a8a;
        border-radius: 3px;

        input {
          position: absolute;
        }
      }

      .upvote-input-container input[type="checkbox"]:not(old) {
        opacity: 0;
        width: 15px;
        height: 15px;
        padding: 0;
        margin: 0;
      }

      .upvote-input-container label {
        position: absolute;
      }

      .upvote-input-container label::after {
        content: "";
        position: absolute;
        top: -4px;
        left: 0px;
        width: 17px;
        height: 15px;
        border-radius: 0px;
        transform: rotate(0);
        pointer-events: none;
        text-align: left;
        color: #8a8a8a;
      }

      .upvote-input-container input[type="checkbox"]:checked + label:after {
        content: url("./assets/img/tick.svg");
        // "\2713";
        // url("./assets/img/tick.svg");
      }
    }
  }

  .upvote-container-actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0px 10px 0px;

    a {
      height: 35px;
      padding: 0px 20px;
      font-size: 12px;
    }
  }
}

.upvote-container-dark {
  display: flex;
  flex-direction: column;
  width: 300px;

  .upvote-container-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex: 1;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    padding: 15px 10px;
    margin: 5px 0px;
    border-color: rgba(255, 255, 255, 0.1) !important;

    &:hover {
      background-color: #313030 !important;
    }

    &:active {
      background-color: #313030 !important;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0.15;
      color: #8a8a8a;

      svg {
        color: #8a8a8a;
        font-size: 27px !important;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex: 0.75;
      padding: 0px 5px;

      .text-top {
        font-size: 10px;
        font-weight: bold;
        color: #8a8a8a;
        font-style: italic;
      }

      .text-bottom {
        font-size: 13px;
        font-weight: normal;
        color: #8a8a8a;
      }
    }

    &__checkbox {
      flex: 0.1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .upvote-input-container {
        width: 15px;
        height: 15px;
        background-color: transparent;
        border: 2px solid #8a8a8a;
        border-radius: 3px;

        input {
          position: absolute;
        }
      }

      .upvote-input-container input[type="checkbox"]:not(old) {
        opacity: 0;
        width: 15px;
        height: 15px;
        padding: 0;
        margin: 0;
      }

      .upvote-input-container label {
        position: absolute;
      }

      .upvote-input-container label::after {
        content: "";
        position: absolute;
        top: -4px;
        left: 0px;
        width: 17px;
        height: 15px;
        border-radius: 0px;
        transform: rotate(0);
        pointer-events: none;
        text-align: left;
        color: #8a8a8a;
      }

      .upvote-input-container input[type="checkbox"]:checked + label:after {
        content: url("./assets/img/tick.svg");
        // "\2713";
        // url("./assets/img/tick.svg");
      }
    }
  }

  .upvote-container-actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0px 10px 0px;

    a {
      height: 35px;
      padding: 0px 20px;
      font-size: 12px;
    }
  }
}

.how-to-media {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    min-width: 100%;
    max-width: 100%;
  }

  h1 {
    min-width: 100%;
    max-width: 100%;
  }
  figure {
    // min-width: 60%;
    // max-width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      height: auto;
    }

    iframe {
      max-width: 100%;
    }
  }

  @media screen and (max-width: 1920px) {
    figure {
      min-width: 45%;
      max-width: 45%;
    }
  }

  @media screen and (max-width: 1440px) {
    figure {
      min-width: 50%;
      max-width: 50%;
    }
  }

  @media screen and (max-width: 870px) {
    figure {
      min-width: 70%;
      max-width: 70%;
    }
  }

  @media screen and (max-width: 770px) {
    figure {
      min-width: 75%;
      max-width: 75%;
    }
  }

  @media screen and (max-width: 670px) {
    figure {
      min-width: 80%;
      max-width: 80%;
    }
  }

  @media screen and (max-width: 570px) {
    figure {
      min-width: 90%;
      max-width: 90%;
    }
  }

  @media screen and (max-width: 470px) {
    figure {
      min-width: 100%;
      max-width: 100%;
    }
  }
}
