.subscription-wrapper {
	// margin-top: -50px;
	.subscription-header {
		font-style: italic;
		padding: 10px;
		color: #808080;
	}
}

.subscribe-notification-modal-dark {
	display: flex;
	align-items: flex-start;
	max-width: 550px;
	overflow-y: scroll;
	overflow-x: hidden;
	max-height: 90%;
	scrollbar-width: thin !important;
	background-color: #3c3c3c;
}

.subscribe-notification-modal-light {
	display: flex;
	align-items: flex-start;
	max-width: 500px;
	overflow-y: scroll;
	overflow-x: hidden;
	max-height: 90%;
	scrollbar-width: thin !important;
	background-color: lightgray;
}

.subscribe-categories-wrapper {
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	justify-content: center;

	.subscribe-categories-list-mobile {
		list-style: none;
		color: gray;
		font-family: montserrat;
		font-size: 13px;
		width: 95%;
		padding: 0px;

		.subed {
			opacity: 1 !important;
		}
		.subscribe-category {
			opacity: 0.35;
			border-style: solid;
			border-width: 2px;
			border-bottom-width: 0px;
			cursor: pointer;
			padding: 12px;
			font-weight: 700;
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 1;
			border-color: rgba(132, 132, 132, 0.2);

			.image {
				display: flex;
				flex: 0.1;
				align-items: center;
				justify-content: center;
				img {
					width: 18px;
					height: 18px;
					margin-right: 10px;
				}
			}

			.content {
				display: flex;
				flex: 0.8;
			}

			.icon {
				display: flex;
				flex: 0.1;
				align-items: center;
				justify-content: center;
				i {
					display: flex;
					flex: 0.1;
				}
			}
		}

		.subscribe-category:hover {
			cursor: pointer;
			background-color: rgba(0, 0, 0, 0.2);
		}
	}

	.subscribe-categories-list {
		list-style: none;
		color: gray;
		font-family: montserrat;
		font-size: 13px;
		width: 50%;

		.subed {
			opacity: 1 !important;
		}
		.subscribe-category {
			opacity: 0.35;
			border-style: solid;
			border-width: 2px;
			border-bottom-width: 0px;
			cursor: pointer;
			padding: 12px;
			font-weight: 700;
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 1;
			border-color: rgba(132, 132, 132, 0.2);

			.image {
				display: flex;
				flex: 0.1;
				align-items: center;
				justify-content: center;
				img {
					width: 18px;
					height: 18px;
					margin-right: 10px;
				}
			}

			.content {
				display: flex;
				flex: 0.8;
			}

			.icon {
				display: flex;
				flex: 0.1;
				align-items: center;
				justify-content: center;
				i {
					display: flex;
					flex: 0.1;
				}
			}
		}

		.subscribe-category:hover {
			cursor: pointer;
			background-color: rgba(0, 0, 0, 0.2);
		}
	}
}
