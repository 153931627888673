@import url("https://fonts.googleapis.com/css?family=Open+Sans:600, 300");

.disclaimer-container {
	background-color: #555;
	color: #fff;
	display: block;
	font-family: "Open Sans", sans-serif;
	position: fixed;
	left: 15px;
	bottom: 13px;
	z-index: 99999;
	width: 270px;
	height: 270px;
	border-radius: 20px;
	padding-top: 0px;
	padding-bottom: 10px;
	padding-left: 25px;
	padding-right: 25px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;

	&--full {
		width: 93%;
		right: 15px;
	}

	.disclaimer-text {
		// padding: 8px;
		// -------------
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 20px;
		width: 100%;
		justify-content: center;
		text-align: center;

		svg {
			font-size: 55px;
			color: #8a8a8a;
		}

		span {
			width: 90%;
			color: #8a8a8a;
			text-align: center;
			font-weight: bold;
			font-size: 13px;
		}

		// --------------
	}

	.disclaimer-text p {
		font-size: 12px;
		line-height: 22px;
		margin: 0;
		text-align: center;
		display: flex;
		flex-direction: column;
	}

	.disclaimer-text p span:before {
		color: #fff;
	}

	.disclaimer-text p a {
		color: #fff;
	}

	.accept-button {
		// margin-left: 16px !important;
		margin: auto;
		margin-top: 10px;
		box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
		// box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
		span {
			&:hover {
				color: white !important;
			}
		}
	}

	a {
		color: #8dcdff !important;
	}
}

.c-dark {
	background-color: #555;
}

.c-light {
	background-color: #edf0f5;
}

.disclaimer-container.show {
	display: block;
}

.pp {
	bottom: 15px;
	position: absolute;
	a {
		text-decoration: none;
		border-bottom-style: 3px;
		border-bottom-color: #8a8a8a;
		b {
			font-size: 10px;
			width: 100%;
			color: #8a8a8a;
			&:hover {
				color: white;
			}
		}
	}

	b {
		color: #8a8a8a;
	}
}
