.menu {
    display: flex;
    justify-content: flex-start;

    position: relative;
    z-index: 1;
    padding: 20px;
    width:100%;
    margin: 20px auto;
    border-bottom: 0px solid #000;
    
    @media (max-width: 650px) {
      align-items: center;
      justify-content: center;
    }

    .light-info {
        color:gray !important;
        background-color: #EDF0F4;
      }

      .dark-info {
      background-color: #3c3c3c;
        color:gray !important;
      }
    
    .item {
        display:flex;
        align-items:center;
        justify-content: center;
      padding:5px 12px;
      cursor: pointer;
      transition: color .3s ease-out;
      text-decoration: none;
      margin:0px 7px;
      font-size: 0.925em !important;
      font-weight: bold;
      border-radius: 0.28571429rem;
      
      &.active {
        color: gray !important;
      }
    }
    
    .indicator {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      border-radius: 0.28571429rem;
      background-color: transparent !important;
      box-shadow: 0 0 5px 0 rgb(0 0 0 / 15%);
    }
  }