.favourite-titles {
	padding: 10px 16px 0px 16px;
}

.favourite-titles-mobile {
	padding: 10px 16px 0px 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}
