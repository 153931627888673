.account-wrapper {
	margin-top: 20px;
	.page-title {
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 4rem;
	}

	.account-form {
		width: 50%;
		margin: auto;
	}

	.title {
		font-size: 13px;
		color: rgba(0, 0, 0, 0.55);
		font-weight: 600;
		margin-bottom: 4px;
	}

	.textfield-container {
		.textfield {
			display: flex;
			flex-direction: column;
			margin-bottom: 1.25rem;

			&:last-child {
				margin-bottom: 0;
			}

			i {
				cursor: pointer;
			}

			input {
				padding: 6px 9px;
				font-size: 15px;
				border: 2px solid rgba(0, 0, 0, 0.15);
				border-radius: 4px;
				width: 100%;

				&.error {
					border-color: red;
					margin-left: 0;
					text-align: left;
				}
			}

			.passwordField-container {
				position: relative;
				width: 100%;

				.eye-icon {
					position: absolute;
					right: 0;
					top: 0;
					height: 100%;
					width: 40px;

					i {
						cursor: pointer;
						position: absolute;
						right: 8px;
						top: 6px;
					}
				}
			}
		}
	}

	.auth-btns {
		text-align: center;
		margin: 1.25rem 0 2rem 0;
		color: white;

		button {
			color: white;
			padding: 14px 27px;
			font-size: 10px;
			border: none;
			box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.125);
			margin-right: 0.5rem;
			cursor: pointer;

			&:last-child {
				margin-right: 0;
			}
		}

		.purple {
			background-color: #a967cb;
		}

		.blue {
			background-color: #4786f0;
		}

		.black {
			background-color: black;
		}
	}
}
